import React from 'react';

import Head from 'components/Head';
import GlobalStyle from 'global.css';

const Layout: React.SFC = ({ children }) => (
  <div>
    <GlobalStyle />
    <Head />
    {children}
  </div>
);

export default Layout;
