import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import BackgroundImage, { IFluidObject } from 'gatsby-background-image';

import mq from 'helpers/mq';
import Layout from 'components/Layout';
import Logo from 'images/logo.svg';
import TwitterIcon from 'images/twitter.svg';
import InstagramIcon from 'images/instagram.svg';
import SpotifyIcon from 'images/spotify.svg';

const screenReaderText = css`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
`;

const Overlay = styled.span`
  background: linear-gradient(to bottom, transparent 0%, black 100%);
  height: 100%;
  position: absolute;
  width: 100%;
`;

const LogoContainer = styled.div`
  position: relative;
  text-align: center;
  ${mq({
    marginBottom: ['0.4em', '1em'],
    marginTop: ['2em', '3em', '5em'],
  })}

  svg {
    ${mq({
      height: ['36px', '78px', '102px'],
      width: ['180px', '390px', '508px'],
    })}
  }
`;

const ScreenReaderTitle = styled.h1`
  ${screenReaderText};
`;

const ScreenReaderSocial = styled.p`
  ${screenReaderText};
`;

const Strapline = styled.p`
  color: rgb(255, 255, 255);
  font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif;
  position: relative;
  text-align: center;
  line-height: 1.3;
  margin: 0 auto 1em;
  ${mq({
    fontSize: ['18px', '28px', '36px'],
    maxWidth: ['260px', 'none'],
  })}
`;

const ComingSoon = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif;
  position: relative;
  text-align: center;
  line-height: 1.6;
  padding: 0 1em;
  ${mq({
    fontSize: ['12px', '16px', '18px'],
    margin: ['0 auto 3em', '0 auto 2em'],
    maxWidth: ['320px', '480px'],
  })}
`;

const SignUpForm = styled.form`
  position: relative;
  text-align: center;
  max-width: 480px;
  margin: 0 auto 3em;
  padding: 0 2em;
`;

const EmailLabel = styled.label`
  ${screenReaderText};
`;

const EmailInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  height: 44px;
  font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif;
  font-size: 16px;
  padding: 0 0.5em;
  width: 100%;
  display: block;
  margin-bottom: 1em;
  ${mq({
    fontSize: ['16px', '19px'],
  })}
`;

const HiddenInput = styled.input`
  position: absolute;
  left: -5000px;
  aria-hidden: true;
`;

const SubmitButton = styled.input`
  border: 2px solid #000;
  border-radius: 3px;
  background: #000;
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', 'Segoe UI', Tahoma, sans-serif;
  padding: 0.6em 1.8em;
  line-height: 1.5;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${mq({
    fontSize: ['14px', '18px'],
  })}

  &:hover {
    cursor: pointer;
    border: 2px solid #ff9f1c;
    background: #ff9f1c;
  }
`;

const SocialList = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;

  > a {
    position: relative;
    ${mq({
      padding: ['0.25em 1em', '0.25em 1.5em'],
    })}

    > svg {
      height: 30px;
      width: 30px;
      ${mq({
        height: ['30px', '38px'],
        width: ['30px', '38px'],
      })}
    }
  }
`;

interface IndexProps {
  data: {
    splashJson: {
      backgroundImage: {
        childImageSharp: {
          fluid: IFluidObject;
        };
      };
    };
  };
}

const Index: React.SFC<IndexProps> = ({ data }) => (
  <Layout>
    <BackgroundImage
      Tag="section"
      fluid={data.splashJson.backgroundImage.childImageSharp.fluid}
      backgroundColor="#000000"
      aria-label="Triathletes in the sea ready to race."
      title="Triathletes in the sea ready to race."
      style={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center 20%',
        height: '100%',
        left: 0,
        minHeight: '1024px',
        position: 'fixed',
        top: 0,
        width: '100%',
      }}
    >
      <Overlay />
    </BackgroundImage>
    <LogoContainer>
      <ScreenReaderTitle>Team Courage</ScreenReaderTitle>
      <Logo />
    </LogoContainer>
    <Strapline>triathlon coaching for ambitious athletes</Strapline>
    <ComingSoon>
      Team Courage HQ is currently in development, sign up to be the first to
      know when we go live…
    </ComingSoon>

    <SignUpForm
      action="https://teamcouragehq.us3.list-manage.com/subscribe/post?u=d402851b0e5a50a1762e93b76&amp;id=0eb98572fb"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <EmailLabel htmlFor="mce-EMAIL">Email Address</EmailLabel>
      <EmailInput
        type="email"
        name="EMAIL"
        id="mce-EMAIL"
        placeholder="Join with email..."
      />
      <HiddenInput
        type="text"
        name="b_d402851b0e5a50a1762e93b76_0eb98572fb"
        tabIndex={-1}
      />
      <SubmitButton type="submit" value="Keep me posted" name="subscribe" />
    </SignUpForm>

    <ComingSoon>You can also find us on social media.</ComingSoon>
    <SocialList>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/teamcouragehq"
      >
        <ScreenReaderSocial>
          Follow Team Courage on Instagram
        </ScreenReaderSocial>
        <InstagramIcon />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/TeamCourageHQ"
      >
        <ScreenReaderSocial>Follow Team Courage on Twitter</ScreenReaderSocial>
        <TwitterIcon />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://open.spotify.com/user/tpqclg719njhcv5kdx23ys2vt"
      >
        <ScreenReaderSocial>Follow Team Courage on Spotify</ScreenReaderSocial>
        <SpotifyIcon />
      </a>
    </SocialList>
  </Layout>
);

export default Index;

export const query = graphql`
  query HomepageQuery {
    splashJson {
      title
      foo
      backgroundImage {
        childImageSharp {
          fluid(maxWidth: 2048, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
