import facepaint from 'facepaint';

export enum BreakPoints {
  Desktop = 992,
  Tablet = 768,
  Phone = 376,
}

export const mq = facepaint(
  [
    `@media(min-width: ${BreakPoints.Phone}px)`,
    `@media(min-width: ${BreakPoints.Tablet}px)`,
    `@media(min-width: ${BreakPoints.Desktop}px)`,
  ],
  { overlap: true },
);

export default mq;
